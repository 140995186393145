import { useQuery } from 'react-query';
import { getPropertyImages } from '../../../services/properties';

export const useGetPropertyImages = (propertyId: number) => useQuery(
  ['propertyImages', propertyId],
  () => getPropertyImages(propertyId),
  {
    refetchOnWindowFocus: false,
  },
);
