import axios from './axios';
import { Query, buildQuery } from '../utils/query';
import { MimeType } from '../redux/types/mime';
import { PropertyDetailsAgent, PropertyInput, DataToSave, PropertyImages } from '../redux/types/properties';
import { ExportAllParams } from '../redux/types/exportAll';

export const loadProperties = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/properties${query}`);
};

export const loadPropertyById = (id: number | string) => axios.get(`/properties/${id}`);

export const getPropertyImages = (id: number | string): Promise<PropertyImages[]> => axios.get(`/properties/${id}/images`).then((res) => res.data);

export const uploadPropertyImages = (id: number, formData: FormData) => axios.post(`/properties/${id}/images`, formData);
export const removePropertyImages = (id: number, imageId: number) => axios.delete(`/properties/${id}/images/${imageId}`);

export const propertyAddAgent = (payload: {
  propertyId: number | string;
  agentId: string;
  is_project_manager: boolean | number;
  email: string;
  phone: string;
}) => axios.patch<PropertyDetailsAgent>(`/properties/${payload.propertyId}/agent`, {
  agentUsername: payload.agentId,
  is_project_manager: payload.is_project_manager,
  email: payload.email,
  phone: payload.phone,
});

export const propertyRemoveAgent = (payload: {
  propertyId: number | string;
  agentId: string;
}) => axios.delete<{ message: string }>(`/properties/${payload.propertyId}/agent`, {
  data: { agentUsername: payload.agentId },
});

export const savePropertyData = (property: PropertyInput) => axios.post(`/properties/${property.property_id}`, property);

export const createPropertyData = (property: PropertyInput) => axios.post('/properties/new_property', property);

export const saveProjectIdData = (data: DataToSave) => axios.patch(`/properties/saveIntegrationsProjectId/${data.property_id}`, data);

export const saveFilterData = (data: DataToSave) => axios.patch('/properties/saveFilterData/', data); // ?...

export const loadPropertiesExport = (
  selection: Array<number | string>,
  mimeType: MimeType,
) => {
  const headers = {
    Accept: mimeType,
  };

  return axios.post(
    '/properties/export',
    {
      selection,
    },
    { headers, responseType: 'blob' },
  );
};

export const exportCommunitiesRequest = (
  selection: Array<number | string>,
  mimeType: MimeType,
) => {
  const headers = {
    Accept: mimeType,
  };

  return axios.post(
    '/communities/export',
    {
      selection,
    },
    { headers, responseType: 'blob' },
  );
};

export const loadPropertyToursReport = (queryObj: any) => {
  const query = buildQuery(queryObj);
  return axios.get(`/properties/properties_by_status${query}`);
};

export const loadPropertyVisitsReportExport = (
  selection: Array<number | string>,
  startDate: Date,
  endDate: Date,
  accept: 'application/pdf' | 'text/csv' = 'application/pdf',
) => {
  const headers = {
    Accept: accept,
  };

  return axios.post(
    '/properties/tourReport/export',
    {
      selection,
      startDate,
      endDate,
    },
    { headers, responseType: 'blob' },
  );
};

export const loadExportAll = (path: string, accept: 'application/pdf' | 'text/csv' = 'application/pdf', params: ExportAllParams, query: Query) => {
  const headers = {
    Accept: accept,
  };
  return axios.post(
    `/${path}/exportAll`,
    { params, query },
    { headers, responseType: 'blob' },
  );
};
