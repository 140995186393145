import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  image: {
    maxWidth: '200px',
    maxHeight: '100px',
    height: 'auto',
  },
  table: {
    '& tr': {
      '&:hover > td': {
        color: theme.appPalette.orange,
        cursor: 'pointer',
      },
    },
  },
});
