import LassoImage from '../../assets/img/lasso.png';
import smartTouchCrmImg from '../../assets/img/smart-touch-crm.png';
import salesForcePlatformImg from '../../assets/img/sales_force_platform.png';
import bildersDesignExperienceImg from '../../assets/img/builders-design-experience.png';
import atlastRTXImg from '../../assets/img/atlastRTX.png';
import arloLogo from '../../assets/img/arlo-logo.png';
import verseLogo from '../../assets/img/verse-Logo-2.png';
import rhLogo from '../../assets/img/RHLogo.png';
import novihomeLogo from '../../assets/img/novihome2.png';
import boomtownLogo from '../../assets/img/boomtown.png';
import academyLogo from '../../assets/img/academy.png';
import followUpBossLogo from '../../assets/img/follow-up-boss.png';
import realtorLogo from '../../assets/img/realtor.png';
import ConnectionIcon from '../../components/Icons/ConnectionIcon';

export const searchBarPlaceholder = 'Search integrations';

export const integrationsLogos = new Map([
  ['Lasso CRM', LassoImage],
  ['AtlastRTX', atlastRTXImg],
  ['Arlo', arloLogo],
  ['Builders Design Experience', bildersDesignExperienceImg],
  ['RH', rhLogo],
  ['Smart Touch CRM', smartTouchCrmImg],
  ['Salesforce API', salesForcePlatformImg],
  ['Novihome', novihomeLogo],
  ['Verse', verseLogo],
  ['Boomtown', boomtownLogo],
  ['Academy', academyLogo],
  ['Follow Up Boss', followUpBossLogo],
  ['Realtor.com', realtorLogo],
]);

export const integrationApis = [
  {
    id: 'createApi',
    name: 'Create Custom API',
    description:
      'Please click this button to let our staff know that you are interested in building a custom API',
    icon: ConnectionIcon,
  },
  // {
  //   id: 'propertyImport',
  //   name: 'Property Import',
  //   description:
  //     'Synchronize with a feed to import your property details into the NterNow system.',
  //   icon: propertyImportImg,
  // }
];
