import ProgressBar from '@ramonak/react-progress-bar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useStyles from './DragUploadImage.styles';
import { ReactComponent as DropZoneIcon } from '../../assets/dropzoneLogo.svg';
import { StatusColor } from '../../types/main';

interface DragUploadImageBoxProps {
  onChange: (file: File | null) => void;
  imagePreview?: string;
  enableUploadStatus?: boolean;
}

export const DragUploadImageBox = (props: DragUploadImageBoxProps) => {
  const { onChange, imagePreview, enableUploadStatus} = props;
  const styleProps = { color: 'red' ?? 'transparent' };
  const classes = useStyles(styleProps);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState({ status: '', value: '' });
  const [color, setColor] = useState('transparent');
  const [filePreview, setFilePreview] = useState<string | null>(imagePreview ?? null);

  useEffect(() => {
    const statusColor: StatusColor = {
      success: '#4BB543',
      error: '#D11A2A',
      loading: '#DB5C0E',
    };

    setColor(statusColor[uploadStatus.status as keyof StatusColor]);
  }, [uploadStatus]);

  const onDrop = useCallback((acceptedFiles) => {
    setProgress(0);
    setUploadStatus({ status: '', value: '' });

    acceptedFiles.forEach((file: any) => {
      const isImage = file.type === 'image/png' || file.type === 'image/jpeg';

      if (!isImage) {
        setUploadStatus({ status: 'error', value: 'Unsupported file type' });

        return;
      }

      if (file.size / (1024 * 1024) > 50) {
        setUploadStatus({ status: 'error', value: 'Max logo size is 50mb' });

        return;
      }

      const reader = new FileReader();

      reader.onprogress = (data) => {
        setUploadStatus({ status: 'loading', value: 'Uploading' });

        if (data.lengthComputable) {
          setProgress((data.loaded / data.total) * 100);
        }
      };

      reader.onerror = () => {
        setUploadStatus({ status: 'error', value: 'Uploading Failed' });
      };

      reader.onload = () => {
        const logo = String(reader.result);
        setFilePreview(logo);

        setUploadStatus({ status: 'success', value: 'Sucessfully Uploaded' });
      };

      const base = reader.readAsDataURL(file);

      onChange(file);

      return base;
    });
  }, [onChange]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className={classes.dropZoneContainer}>
      <div className={classes.dropZone} {...getRootProps()}>
        <input {...getInputProps()} />
        {filePreview ? (
          <img src={filePreview} alt="logo" className={classes.logo} />
        ) : (
          <DropZoneIcon />
        )}
        <h3>Drop your logo here or browse</h3>
        <span className={classes.supportText}>
          {' '}
          Support: JPG, JPEG, PNG
          {' '}
        </span>
      </div>

      {enableUploadStatus && uploadStatus.status && (
        <>
          <p className={classes.progressStatus} style={{ color }}>
            {uploadStatus.value}
          </p>
          <ProgressBar
            className={classes.progressBar}
            completed={progress}
            isLabelVisible={false}
            bgColor={color}
            baseBgColor="transparent"
            transitionDuration="2s"
            // @ts-ignore
            color={color}
          />
        </>
      )}
    </div>
  );
};
