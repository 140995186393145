import { withStyles } from '@material-ui/styles';

import { connect } from 'react-redux';
import PropertyImages from './PropertyImages';
import styles from './styles';
import { AppState } from '../../redux/store';
import { getBuilderId } from '../../redux/selectors/auth';

const mapStateToProps = (state: AppState) => ({
  builderId: getBuilderId(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PropertyImages));
