import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card/';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import styles from './styles';
import { getIntegrationStatus, updateIntegrationStatus } from '../../services/intergations';

type Props = WithStyles<typeof styles> & {
  id: number;
  imgSource: string;
  name: string;
  description: string;
};

const IntegrationWidget: React.FC<Props> = props => {
  const {
    classes, id, imgSource, name, description,
  } = props;
  const [switchChecked, handleChecked] = useState<boolean>(false);
  const handleSwitch = () => {
    const newStatus = !switchChecked;
    handleChecked(newStatus);

    const updateStatus = async () => {
      console.log(!switchChecked);
      await updateIntegrationStatus(id, newStatus);
    }

    updateStatus();
  };

  useEffect(() => {
    const fetchStatus = async () => {
      const isActive = await getIntegrationStatus(id);
       handleChecked(isActive)
    }

    fetchStatus();
  }, [id]);


  return (
    <>
      <Card elevation={3} className={classes.card}>
        <CardContent>
          <div className={classes.switchWrapper}>
            <Switch checked={switchChecked} onChange={handleSwitch} />
          </div>

          <div className={classes.logoWrapper}>
            <img style={{ maxHeight: '150px' }} src={imgSource} alt="" className={classes.image} />
          </div>

          <Typography className={classes.apiName}>{name}</Typography>
          <Typography className={classes.apiDescription}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default React.memo(IntegrationWidget);
