import { connect } from 'react-redux';
import {
  propertiesRequest,
  propertiesExportRequest,
  propertiesExportAndPrintRequest,
} from '../../redux/actions/properties';
import { exportAllRequest } from '../../redux/actions/exportAll';
import { clearFilter } from '../../redux/actions/filter';

import {
  selectProperties,
  selectPropertiesRequestStatus,
  selectTotalProperties,
} from '../../redux/selectors/properties';
import { selectExportAllLoadingState } from '../../redux/selectors/exportAll';

import Properties from './Properties';
import { AppState } from '../../redux/store';
import { getBuilderId } from '../../redux/selectors/auth';


const mapStateToProps = (state: AppState) => ({
  properties: selectProperties(state),
  loading: selectPropertiesRequestStatus(state),
  propertiesTotal: selectTotalProperties(state),
  exportAllLoading: selectExportAllLoadingState(state),
  builderId: getBuilderId(state),
});

const mapDispatchToProps = {
  loadProperties: propertiesRequest,
  exportProperties: propertiesExportRequest,
  printProperties: propertiesExportAndPrintRequest,
  exportAllProperties: exportAllRequest,
  clearFilterState: clearFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Properties);
