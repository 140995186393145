import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { useMutateUploadPropertyImage } from './hooks/useMutateUploadPropertyImage';
import queryClient from '../../utils/queryClient';
import { DragUploadImageBox } from './DragUploadImage';

interface DialogUploadImageProps {
  open: boolean;
  edit: boolean;
  onClose: () => void;
  propertyId: number;
  seqPosition: number;
  imagePreview?: string;
}

export const DialogUploadImage: React.FC<DialogUploadImageProps> = ({
  open, seqPosition,
  edit, onClose, propertyId,
  imagePreview,
}) => {
  const [sequencePosition, setSequencePosition] = useState<number>(seqPosition);
  const [file, setFile] = useState<File | null>(null);
  const { mutateAsync: mutateUploadImage, isLoading } = useMutateUploadPropertyImage(propertyId);

  useEffect(() => {
    setSequencePosition(seqPosition);
  }, [seqPosition]);

  const uploadFile = async () => {
    if (file) {
      await mutateUploadImage({
        file,
        sequencePosition,
      });
      queryClient.invalidateQueries(['propertyImages', propertyId]);
      setFile(null);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{edit ? 'Change Photo' : 'Upload new photo'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DragUploadImageBox imagePreview={imagePreview} onChange={(f) => setFile(f)} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="number"
              margin="normal"
              fullWidth
              label="Sequence Position"
              name="sequencePosition"
              disabled={edit}
              value={sequencePosition}
              onChange={(e: any) => {
                setSequencePosition(Number(e.target.value));
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          disabled={isLoading}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={!file || isLoading}
          onClick={() => {
            uploadFile();
          }}
        >
          {isLoading ? 'Uploading...' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
