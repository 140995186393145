/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Spinner from '@material-ui/core/CircularProgress';
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { useDispatch } from 'react-redux';
import CustomButton from '../CustomButton';
import CommunitySearch from '../../containers/CommunitySearch';
import InputPriceWrapper from '../InputPriceWrapper';
import { State } from '../../redux/types/states';
import { IntegrationsInfo, PropertyInput } from '../../redux/types/properties';
import styles from './styles';
import {
  ValidationErrors,
  beautifyErrors,
  identicalCodesSchema,
} from '../../utils/helpers';
import TimezoneSelect from '../Timezone';
import { ActionPayload } from '../../types/main';
import { createProperty as createPropertyAction } from '../../redux/actions/properties';
import { Agent } from '../../redux/types/agents';
import Staff from '../Staff';
import PropertyTourHours from '../TourHours';
import { automationsSchema, propertyCreateSchema } from './PropertyValidation';
import {
  SelectedCommunity,
  TourHoursType,
  WeeklyTourHours,
} from '../../redux/types/communities';
import { createErrorSnackBar } from '../../redux/actions/snackbars';
import { Team } from '../../redux/types/teams';
import { Builder } from '../../redux/types/builders';

type Props = {
  states: State[];
  loadingStates: boolean;
  loadingEnterpriseBuilders: boolean;
  loadingCommunity: boolean;
  loadStates: () => void;
  loadTeams: () => void;
  loadTeamsByBuilder: (builderId: number) => void;
  loadCommunityDetails: (communityId: number) => void;
  loadAvailableAgents: (builderId: number) => void;
  loadIntegrations: () => void;
  loadEnterpriseBuilders: (enterpriseId: number) => void;
  createProperty: (payload: ActionPayload<typeof createPropertyAction>) => void;
  builder: string;
  builder_id: number;
  enterpriseId: number | null;
  enterpriseBuilders: Builder[];
  builderIntegrations: IntegrationsInfo | null;
  communities: any;
  teams: Team[];
  communityDetails: SelectedCommunity | null;
  isRental: boolean;
  isEnterprise: boolean;
} & WithStyles<typeof styles>;

const Connector = withStyles({
  alternativeLabel: { top: 9 },
  active: { '& $line': { borderColor: '#DB5C0E' } },
  completed: { '& $line': { borderColor: '#DB5C0E' } },
  line: {
    borderTopWidth: 2,
  },
  vertical: {
    marginLeft: 9,
  },
  lineVertical: {
    borderLeftWidth: 2,
  },
})(StepConnector);

const defaultSteps = [
  'Property Info',
  'Add Users',
  'Tour Hours',
  'Automations',
];

const InputProperty: React.FC<Props> = (props) => {
  const {
    classes,
    loadStates,
    loadingCommunity,
    loadTeams,
    loadTeamsByBuilder,
    loadCommunityDetails,
    loadAvailableAgents,
    loadingStates,
    loadingEnterpriseBuilders,
    loadIntegrations,
    loadEnterpriseBuilders,
    states,
    teams,
    builder,
    builder_id,
    enterpriseId,
    enterpriseBuilders,
    builderIntegrations,
    createProperty,
    communities,
    communityDetails,
    isRental,
    isEnterprise,
  } = props;

  const [steps, setSteps] = useState<string[]>(defaultSteps);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<PropertyInput>
  >({});
  const [propertyTourHours, setPropertyTourHours] = useState<
  WeeklyTourHours[] | null
  >(null);
  const [communityTourHours, setCommunityTourHours] = useState<
  TourHoursType[] | null
  >(null);
  const [selectedBuilder, setSelectedBuilder] = useState<number | null>(null);
  const [property, setProperty] = useState<PropertyInput>({
    property_id: 0,
    builder: enterpriseId ? '' : builder,
    house_num: '',
    address_1: '',
    community_id: null,
    subdivision: '',
    city: '',
    state: '',
    zipcode: '',
    latitude: 0,
    longitude: 0,
    url_1: '',
    rental_application_url: '',
    price: 0,
    bedrooms: 0,
    halfBathrooms: 0,
    fullBathrooms: 0,
    squareFootage: 0,
    isModel: false,
    timezone: 0,
    tourHoursStart: 5,
    tourHoursEnd: 23,
    alarm: false,
    alarm_company: '',
    armed_hours: '',
    disarm_code: '',
    emergency_notes: '',
    mls_number: '',
    external_identifier: '',
    external_identifier_2: '',
  });

  const dispatch = useDispatch();

  const isIntegrationsActive = builderIntegrations?.some(
    ({ isActive }) => isActive
  );

  useEffect(() => {
    if (isIntegrationsActive) {
      setSteps([
        'Property Info',
        'Add Users',
        'Tour Hours',
        'Automations',
        'Integrations',
      ]);
    }
  }, [builderIntegrations, isIntegrationsActive]);

  useEffect(() => {
    const selectedCommunity = communities.find(
      (community: any) => property.community_id === community.id,
    );

    if (selectedCommunity?.tourHoursCommunity.length > 0) {
      setCommunityTourHours(selectedCommunity?.tourHoursCommunity);
    }
  }, [communities, property]);

  useEffect(() => {
    setProperty((prevState) => ({ ...prevState, propertyTourHours }));
  }, [propertyTourHours]);

  useEffect(() => {
    if (communityDetails) {
      setProperty((prevState) => ({
        ...prevState,
        agents: communityDetails.agents,
        teams: communityDetails.teams,
      }));
    }
  }, [communityDetails]);

  useEffect(() => {
    loadIntegrations();
  }, [loadIntegrations]);

  useEffect(() => {
    loadStates();
  }, [loadStates]);

  useEffect(() => {
    if (!enterpriseId) {
      loadTeams();
    }

    if (enterpriseId && selectedBuilder) {
      loadTeamsByBuilder(selectedBuilder);
    }
  }, [loadTeams, loadTeamsByBuilder, enterpriseId, selectedBuilder]);

  useEffect(() => {
    if (!enterpriseId) {
      loadAvailableAgents(builder_id);
    }

    if (enterpriseId && selectedBuilder) {
      loadAvailableAgents(selectedBuilder);
    }
  }, [loadAvailableAgents, builder_id, enterpriseId, selectedBuilder]);

  useEffect(() => {
    if (enterpriseId) {
      loadEnterpriseBuilders(enterpriseId);
    }
  }, [loadEnterpriseBuilders, enterpriseId]);

  useEffect(() => {
    if (!enterpriseId) {
      setSelectedBuilder(builder_id);
    }
  }, [builder_id, enterpriseId]);

  const handlePreviousPage = () => {
    setPageIndex(pageIndex - 1);
  };

  const handleNextPage = () => {
    if (loadingCommunity) {
      return;
    }

    setPageIndex(pageIndex + 1);
  };

  const handlePropertyChange = (
    payload: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { name, value },
    } = payload;
    const digitsOnlyFields = [
      'property_backup_code',
      'trade_code',
      'sales_code',
    ];

    if (digitsOnlyFields.includes(name) && Number.isNaN(Number(value))) {
      return;
    }

    if (name) {
      setProperty((prevProperty: PropertyInput) => ({
        ...prevProperty,
        [name]: value,
      }));
    }

    if (name === 'isModel') {
      setProperty((prevProperty: PropertyInput) => ({
        ...prevProperty,
        isModel: true,
      }));
    }

    if (name === 'notIsModel') {
      setProperty((prevProperty: PropertyInput) => ({
        ...prevProperty,
        isModel: false,
      }));
    }

    if (name === 'alarm') {
      setProperty((prevProperty: PropertyInput) => ({
        ...prevProperty,
        alarm: true,
      }));
    }

    if (name === 'notAlarm') {
      setProperty((prevProperty: PropertyInput) => ({
        ...prevProperty,
        alarm: false,
        alarm_company: '',
        armed_hours: '',
        disarm_code: '',
        emergency_notes: '',
      }));
    }
  };

  const handleCommunityChange = (value: number, rest?: any) => {
    loadCommunityDetails(value);
    setProperty((prevProperty: PropertyInput) => ({
      ...prevProperty,
      community_id: value,
      subdivision: rest?.label,
      city: rest?.city || '',
      state: rest?.state || '',
      zipcode: rest?.zipcode || '',
      LASSO_project_id: rest?.lasso_community_project_id || '',
      novihome_integration: rest?.novihome_integration || '',
      property_backup_code: rest?.community_backup_code || '',
      trade_code: rest?.trade_code || '',
      sales_code: rest?.sales_code || '',
    }));
  };

  const handlePropertyInfo = () => {
    try {
      const uProperty = propertyCreateSchema.validateSync(property, {
        abortEarly: false,
      }) as PropertyInput;

      if (!uProperty) {
        return;
      }

      setValidationErrors({});
      handleNextPage();
    } catch (e) {
      setValidationErrors(beautifyErrors<PropertyInput>(e));
    }
  };

  const handleAddUsers = (agents: Agent[], teamIds?: number[]) => {
    setProperty({
      ...property,
      agents,
      teams: teamIds ?? property.teams,
    });
  };

  const handleTourHours = (weeklyTourHours: WeeklyTourHours[] | null) => {
    setPropertyTourHours(weeklyTourHours);
  };

  const handlePropertySave = () => {
    try {
      const codes = {
        property_backup_code: property.property_backup_code ?? '',
        trade_code: property.trade_code ?? '',
        sales_code: property.sales_code ?? '',
      };

      identicalCodesSchema.validateSync(codes, { abortEarly: false });

      const uProperty = propertyCreateSchema.validateSync(property, {
        abortEarly: false,
      }) as PropertyInput;

      setValidationErrors({});
      createProperty(uProperty);
    } catch (e) {
      setValidationErrors(beautifyErrors<PropertyInput>(e));
    }
  };

  const handleAutomations = () => {
    try {
      const codes = {
        property_backup_code: property.property_backup_code ?? '',
        trade_code: property.trade_code ?? '',
        sales_code: property.sales_code ?? '',
      };

      identicalCodesSchema.validateSync(codes, { abortEarly: false });
      automationsSchema.validateSync(property, { abortEarly: false });

      setValidationErrors({});
      setPageIndex(pageIndex + 1);
    } catch (errors) {
      setValidationErrors(beautifyErrors<PropertyInput>(errors));
    }
  };

  const handleSelectBuilder = (e: React.ChangeEvent<HTMLInputElement>) => {
    const builderId = Number(e.target.value);
    const selected = enterpriseBuilders.find(
      ({ builder_id }) => builder_id === builderId,
    );

    if (selected) {
      setSelectedBuilder(builderId);
      setProperty((prevState) => ({
        ...prevState,
        builder: selected.name,
        builder_id: selected.builder_id,
        community_id: null,
        subdivision: '',
        city: '',
        state: '',
        zipcode: '',
        LASSO_project_id: '',
        novihome_integration: '',
        property_backup_code: '',
        trade_code: '',
        sales_code: '',
      }));
    }
  };

  return (
    <>
      <Grid container justify="space-between" style={{ marginBottom: 25 }}>
        <Grid item>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/properties"
          >
            <ArrowLeftIcon />
            Properties
          </Button>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <div className={classes.headerText}>
          <span>Property Wizard</span>
        </div>
        <Box style={{ width: '60%', marginLeft: '20%' }}>
          <Stepper
            alternativeLabel
            className={classes.stepper}
            activeStep={pageIndex}
            connector={<Connector />}
          >
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        active: classes.stepIcon,
                        completed: classes.stepIcon,
                      },
                    }}
                    {...labelProps}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        {pageIndex === 0 && (
          <>
            <div className={classes.headerText}>
              <span>Property Info</span>
            </div>
            <div className={classes.inputForm}>
              <Grid container spacing={4}>
                {isEnterprise && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={!enterpriseId ? classes.disabled : ''}
                >
                  <TextField
                    select
                    required
                    fullWidth
                    margin="normal"
                    id="state"
                    name="state"
                    label="Select Builder"
                    value={enterpriseId ? selectedBuilder ?? '' : builder_id}
                    onChange={handleSelectBuilder}
                    disabled={!enterpriseId || (!enterpriseId && loadingEnterpriseBuilders)}
                    helperText={
                      validationErrors.state && 'Builder field is required'
                    }
                    error={Boolean(validationErrors.builder)}
                  >
                    <MenuItem value="" disabled>
                      Select Builder
                    </MenuItem>
                    {enterpriseId ? (
                      enterpriseBuilders.map(
                        ({ builder_id: builderId, name }) => (
                          <MenuItem key={builderId} value={builderId}>
                            {name}
                          </MenuItem>
                        ),
                      )
                    ) : (
                      <MenuItem key={builder_id} value={builder_id}>
                        {builder}
                      </MenuItem>
                    )}
                  </TextField>
                </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={4}
                  direction="column"
                  container
                  justify="flex-end"
                >
                  <div
                    className={
                      (enterpriseId && !selectedBuilder) || loadingCommunity
                        ? classes.disabled
                        : ''
                    }
                  >
                    <CommunitySearch
                      builderId={
                        enterpriseId && selectedBuilder
                          ? selectedBuilder
                          : builder_id
                      }
                      initialValue={property.community_id}
                      handleChange={handleCommunityChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TimezoneSelect
                    fullWidth
                    margin="normal"
                    id="timezone"
                    name="timezone"
                    label="Timezone"
                    required
                    defaultTimezone={property.timezone}
                    onChange={handlePropertyChange}
                    helperText={validationErrors.timezone}
                    error={Boolean(validationErrors.timezone)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="house_num"
                    name="house_num"
                    label="Lot #"
                    value={property.house_num}
                    onChange={handlePropertyChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    margin="normal"
                    id="address_1"
                    name="address_1"
                    label="Full Street Address"
                    value={property.address_1}
                    onChange={handlePropertyChange}
                    helperText={
                      validationErrors.address_1 && 'Address field is required'
                    }
                    error={Boolean(validationErrors.address_1)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="subdivision"
                    name="subdivision"
                    label="Subdivision"
                    value={property.subdivision}
                    onChange={handlePropertyChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="marketing_name"
                    name="marketing_name"
                    label="Marketing Name"
                    value={property.marketing_name}
                    onChange={handlePropertyChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    margin="normal"
                    id="city"
                    name="city"
                    label="City"
                    value={property.city}
                    onChange={handlePropertyChange}
                    helperText={
                      validationErrors.city && 'City field is required'
                    }
                    error={Boolean(validationErrors.city)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div>
                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      id="state"
                      name="state"
                      label="State/Province"
                      value={property.state}
                      onChange={handlePropertyChange}
                      disabled={loadingStates}
                      helperText={
                        validationErrors.state && 'State field is required'
                      }
                      error={Boolean(validationErrors.state)}
                    >
                      <MenuItem value="" disabled>
                        Select State
                      </MenuItem>
                      {states.map(({ state_id, state_name }) => (
                        <MenuItem key={state_id} value={state_id}>
                          {state_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    margin="normal"
                    id="zipcode"
                    name="zipcode"
                    label="Zip code"
                    value={property.zipcode}
                    onChange={handlePropertyChange}
                    helperText={
                      validationErrors.zipcode && 'Zipcode field is required'
                    }
                    error={Boolean(validationErrors.zipcode)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="url_1"
                    name="url_1"
                    label="Url"
                    value={property.url_1}
                    onChange={handlePropertyChange}
                  />
                </Grid>

                {isRental && (
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="rental_application_url"
                      name="rental_application_url"
                      label="Rental Application Url"
                      value={property.rental_application_url}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={4}>
                  <InputPriceWrapper>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="price"
                      name="price"
                      label="Price"
                      value={property.price}
                      onChange={handlePropertyChange}
                      helperText={
                        validationErrors.price && 'Price must be a number'
                      }
                      error={Boolean(validationErrors.price)}
                    />
                  </InputPriceWrapper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="bedrooms"
                    name="bedrooms"
                    label="Bedrooms"
                    value={property.bedrooms}
                    onChange={handlePropertyChange}
                    helperText={
                      validationErrors.bedrooms && 'Bedrooms field is required'
                    }
                    error={Boolean(validationErrors.bedrooms)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="fullBathrooms"
                    name="fullBathrooms"
                    label="Full Bathrooms"
                    value={property.fullBathrooms}
                    onChange={handlePropertyChange}
                    helperText={
                      validationErrors.fullBathrooms
                      && 'Full Bathrooms field is required'
                    }
                    error={Boolean(validationErrors.fullBathrooms)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="halfBathrooms"
                    name="halfBathrooms"
                    label="Half Bathrooms"
                    value={property.halfBathrooms}
                    onChange={handlePropertyChange}
                    helperText={
                      validationErrors.halfBathrooms
                      && 'Half Bathrooms field is required'
                    }
                    error={Boolean(validationErrors.halfBathrooms)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="squareFootage"
                    name="squareFootage"
                    label="Square Footage"
                    value={property.squareFootage}
                    onChange={handlePropertyChange}
                    helperText={
                      validationErrors.squareFootage
                      && 'Square Footage field is required'
                    }
                    error={Boolean(validationErrors.squareFootage)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="mlsNumber"
                    name="mls_number"
                    label="MLS Number"
                    value={property.mls_number}
                    onChange={handlePropertyChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="externalIdentifier"
                    name="external_identifier"
                    label="External Identifier"
                    value={property.external_identifier}
                    onChange={handlePropertyChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="externalIdentifier2"
                    name="external_identifier_2"
                    label="External Identifier 2"
                    value={property.external_identifier_2}
                    onChange={handlePropertyChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.isModel}>Is Model</Typography>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="secondary"
                        name="isModel"
                        checked={property.isModel}
                        onChange={handlePropertyChange}
                      />
                    )}
                    label="Yes"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="secondary"
                        name="notIsModel"
                        checked={!property.isModel}
                        onChange={handlePropertyChange}
                      />
                    )}
                    label="No"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.isModel}>Alarm</Typography>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="secondary"
                        name="alarm"
                        checked={property.alarm}
                        onChange={handlePropertyChange}
                      />
                    )}
                    label="Yes"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="secondary"
                        name="notAlarm"
                        checked={!property.alarm}
                        onChange={handlePropertyChange}
                      />
                    )}
                    label="No"
                  />
                </Grid>
                <Collapse
                  in={property.alarm}
                  className={classes.alarmContainer}
                >
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        margin="normal"
                        id="alarm_company"
                        name="alarm_company"
                        label="Alarm Company"
                        value={property.alarm_company ?? ''}
                        onChange={handlePropertyChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        margin="normal"
                        id="armed_hours"
                        name="armed_hours"
                        label="Armed Hours"
                        value={property.armed_hours ?? ''}
                        onChange={handlePropertyChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        margin="normal"
                        id="disarm_code"
                        name="disarm_code"
                        label="Disarm code"
                        value={property.disarm_code ?? ''}
                        onChange={handlePropertyChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        multiline
                        margin="normal"
                        rows={4}
                        id="emergency_notes"
                        name="emergency_notes"
                        label="Emergency Notes"
                        value={property.emergency_notes ?? ''}
                        onChange={handlePropertyChange}
                        inputProps={{
                          style: {
                            padding: '0 12px',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </div>
            <div className={classes.navButtons}>
              <CustomButton
                variant="orange"
                disabled={loadingCommunity}
                onClick={handlePropertyInfo}
              >
                Next
              </CustomButton>
              {loadingCommunity && <Spinner size={20} />}
            </div>
          </>
        )}
        {pageIndex === 1 && (
          <>
            <div className={classes.headerText}>
              <span>Add Users & Teams</span>
            </div>
            <Staff
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
              handleAddUsers={handleAddUsers}
              teamIds={property.teams}
              teams={teams}
              agents={teams.length === 0 ? property?.agents : []}
            />
            <div className={classes.navButtons}>
              <CustomButton variant="white" onClick={handlePreviousPage}>
                Previous
              </CustomButton>
              <CustomButton
                variant="orange"
                onClick={() => {
                  const assignedPm = property?.agents?.some(
                    (agent) => agent.is_project_manager
                  );

                  if (!assignedPm) {
                    dispatch(
                      createErrorSnackBar('A PM is required to be assigned!'),
                    );
                    return;
                  }

                  handleNextPage();
                }}
              >
                Next
              </CustomButton>
            </div>
          </>
        )}
        {pageIndex === 2 && (
          <>
            <div className={classes.headerText}>
              <span>Tour Hours</span>
            </div>
            <Grid container className={classes.inputForm}>
              <PropertyTourHours
                variant="property"
                handleTourHours={handleTourHours}
                communityHours={communityTourHours}
              />
            </Grid>
            <div className={classes.navButtons}>
              <CustomButton variant="white" onClick={handlePreviousPage}>
                Previous
              </CustomButton>
              <CustomButton variant="orange" onClick={handleNextPage}>
                Next
              </CustomButton>
            </div>
          </>
        )}

        {pageIndex === 3 && (
          <>
            <div className={classes.headerText}>
              <p>Automations</p>
            </div>
            <Grid container spacing={4} className={classes.inputForm}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="property_backup_code"
                  name="property_backup_code"
                  label="Property Master Code"
                  value={property.property_backup_code ?? ''}
                  onChange={handlePropertyChange}
                  error={Boolean(validationErrors.property_backup_code)}
                  helperText={validationErrors.property_backup_code}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="trade_code"
                  name="trade_code"
                  label="Trade Code"
                  value={property.trade_code ?? ''}
                  onChange={handlePropertyChange}
                  error={Boolean(validationErrors.trade_code)}
                  helperText={validationErrors.trade_code}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="sales_code"
                  name="sales_code"
                  label="Sales Code"
                  value={property.sales_code ?? ''}
                  onChange={handlePropertyChange}
                  error={Boolean(validationErrors.sales_code)}
                  helperText={validationErrors.sales_code}
                />
              </Grid>
            </Grid>
            <div className={classes.navButtons}>
              <CustomButton variant="white" onClick={handlePreviousPage}>
                Previous
              </CustomButton>
              <CustomButton
                variant="orange"
                onClick={() => {
                  if (isIntegrationsActive) {
                    handleAutomations();
                    return;
                  }

                  handlePropertySave();
                }}
              >
                {!isIntegrationsActive ? 'Save' : 'Next'}
              </CustomButton>
            </div>
          </>
        )}

        {pageIndex === 4 && isIntegrationsActive && (
          <>
            <div className={classes.headerText}>
              <span>Integrations</span>
            </div>
            <div className={classes.inputForm}>
              <List component="ul">
                {builderIntegrations?.map((item: any) => {
                  if (item.name === 'Lasso CRM') {
                    return (
                      <ListItem component="li" key={item.name}>
                        <Grid item xs={12}>
                          <Typography variant="h6">{item.name}</Typography>
                          <TextField
                            autoComplete="off"
                            margin="normal"
                            id={`${item.name}_project_id`}
                            name="LASSO_project_id"
                            label="Project id"
                            value={property.LASSO_project_id ?? ''}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const { value } = e.target;
                              const isProjectIdValid = !Number.isNaN(
                                Number(value),
                              );

                              if (!isProjectIdValid && value.length > 0) {
                                return;
                              }

                              setProperty((prevState) => ({
                                ...prevState,
                                LASSO_project_id: value || null,
                              }));
                            }}
                          />
                        </Grid>
                      </ListItem>
                    );
                  }

                  if (item.name === 'Novihome') {
                    return (
                      <ListItem component="li" key={item.name}>
                        <Grid item xs={12}>
                          <Typography variant="h6">{item.name}</Typography>
                          <TextField
                            autoComplete="off"
                            margin="normal"
                            id="novihome_integration"
                            name="novihome_integration"
                            label="Novihome Integration"
                            value={property.novihome_integration ?? ''}
                            onChange={handlePropertyChange}
                          />
                        </Grid>
                      </ListItem>
                    );
                  }
                })}
              </List>
            </div>
            <div className={classes.navButtons}>
              <CustomButton variant="white" onClick={handlePreviousPage}>
                Previous
              </CustomButton>
              <CustomButton
                variant="orange"
                onClick={() => {
                  handlePropertySave();
                }}
              >
                Save
              </CustomButton>
            </div>
          </>
        )}
      </Paper>
    </>
  );
};

export default React.memo(InputProperty);
