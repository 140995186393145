import { useQuery } from 'react-query';
import { getBuilderDetail } from '../services/builders';

export const useGetBuilderDetail = (id: number) => useQuery(
  ['useGetBuilderDetail', id],
  () => getBuilderDetail(id).then((res) => res.data),
  {
    enabled: !!id,
    refetchOnWindowFocus: false,
  },
);
