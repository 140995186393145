import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import { useMutateRemoveImage } from './hooks/useMutateUploadPropertyImage';
import queryClient from '../../utils/queryClient';

interface DialogUploadImageProps {
  open: boolean;
  onClose: () => void;
  propertyId: number;
  imageId?: number;
}

export const DialogConfirmDelete: React.FC<DialogUploadImageProps> = ({
  open, onClose, propertyId, imageId,
}) => {
  const { mutateAsync: mutateRemoveImage, isLoading } = useMutateRemoveImage(propertyId);

  const handleDeleteImage = async () => {
    if (imageId) {
      await mutateRemoveImage(imageId);
      queryClient.invalidateQueries(['propertyImages', propertyId]);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogTitle>Do you want to remove this photo?</DialogTitle>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          disabled={isLoading}
          onClick={onClose}
        >
          Cancel
        </Button>
        <div>
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleDeleteImage}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Remove' }
          </Button>

        </div>
      </DialogActions>
    </Dialog>
  );
};
