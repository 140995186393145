import axios from './axios';

export const sendIntergationsRequest = (intergation: { message: string, name: string }) => axios.patch('/integrations', { intergation });

export const getIntegrationsRequest = () => axios.get('/integrations/list');

export const getIntegrationStatus = async (integrationId: number) => {
  try {
    const result = await axios.get(`/integrations/${integrationId}`);

    if (result.data) {
      return result.data.isActive;
    }
  } catch(e) {
    console.error(e);
  }
}

export const updateIntegrationStatus = async (integrationId: number, isActive: boolean) => {
  try {
    await axios.patch(`/integrations/${integrationId}`, { isActive });
  } catch(e) {
    console.error(e);
  }
}
