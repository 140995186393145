import { useMutation } from 'react-query';
import {removePropertyImages, uploadPropertyImages} from '../../../services/properties';

export const useMutateUploadPropertyImage = (propertyId: number) => useMutation({
  mutationFn: ({ file, sequencePosition }: { file: File, sequencePosition: number }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('sequence_position', `${sequencePosition}`);
    return uploadPropertyImages(propertyId, formData);
  },
});

export const useMutateRemoveImage = (propertyId: number) => useMutation({
  mutationFn: (imageId: number) => removePropertyImages(propertyId, imageId),
});
