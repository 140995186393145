import React, { useEffect } from 'react';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import styles from './styles';
import SearchBar from '../SearchBar';
import PropertyImportWidget from '../../components/PropertyImportWidget';
import IntegrationButton from '../../components/IntegrationButton';
import { integrationsLogos, integrationApis, searchBarPlaceholder } from './constants';
import { Integration } from '../../redux/types/integrations';
import PropertyImportImg from '../../assets/img/property_import.png';
import { getBuilderId } from '../../redux/selectors/auth';
import IntegrationRequestWidget from '../../components/IntegrationRequestWidget';
import IntegrationWidget from '../../components/IntegrationWidget';

type Props = {
  getIntegrations: () => void;
  getBuilderFeedSettings: (builderId: number) => void;
  integrations: Integration[]
} & WithStyles<typeof styles>;

const Integrations: React.FC<Props> = (props) => {
  const {
    classes,
    getIntegrations,
    getBuilderFeedSettings,
    integrations,
  } = props;
  const builderId = useSelector(getBuilderId);
  const selfEnablingIntegrationsIds = [14];
  const integrationsToHideIds = [4, 10, 13];


  useEffect(() => {
    getIntegrations();
    getBuilderFeedSettings(builderId);
  }, [getIntegrations, getBuilderFeedSettings, builderId]);

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar placeholder={searchBarPlaceholder} />
        </Grid>
      </Grid>

      <div className={classes.widgetsWrapper}>
        <Grid container spacing={4}>
          <Grid item xs={6} sm={3}>
            <PropertyImportWidget
              name="Property Import"
              description="Synchronize with a feed to import your property details into the NterNow system"
              imgSource={PropertyImportImg}
            />
          </Grid>
          {integrations.map((integration) => ( selfEnablingIntegrationsIds.includes(integration.id) &&
            <Grid key={integration.name} item xs={6} sm={3}>
              <IntegrationWidget
                id={integration.id}
                name={integration.name}
                description={integration.description}
                imgSource={integrationsLogos.get(integration.name)}
              />
            </Grid>
          ))}
          {integrations.map((integration) => ( (!selfEnablingIntegrationsIds.includes(integration.id) && !integrationsToHideIds.includes(integration.id)) &&
            <Grid key={integration.name} item xs={6} sm={3}>
              <IntegrationRequestWidget
                name={integration.name}
                description={integration.description}
                imgSource={integrationsLogos.get(integration.name)}
              />
            </Grid>
          ))}
          {integrationApis.map((api) => (
            <Grid key={api.id} item xs={6} sm={3}>
              <IntegrationButton
                name={api.name}
                description={api.description}
                icon={api.icon}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      {/* <Drawer
        anchor="right"
        classes={{ paper: classes.drawerRoot }}
        open={Boolean(open)}
        onClose={handleClose}
      >
        {open === 'createApi' && <DrawerCreateApi onClose={handleClose} />}
        {open === 'addIntegration' && (
          <DrawerAddIntegration onClose={handleClose} />
        )}
      </Drawer> */}
    </>
  );
};

export default React.memo(Integrations);
