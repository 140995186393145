import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, WithStyles,
} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import { useGetPropertyImages } from './hooks/useGetPropertyImages';
import { DialogUploadImage } from './DialogUploadImage';
import { DialogConfirmDelete } from './DialogConfirmDelete';
import { useGetBuilderDetail } from '../../hooks/useGetBuilderDetail';

type Props = WithStyles<typeof styles> & {
  propertyId: number;
  builderId: number;
};

const PropertyImageComponent: React.FC<Props> = (props) => {
  const { classes, propertyId, builderId } = props;
  const { data: builder } = useGetBuilderDetail(builderId);

  const { data: images } = useGetPropertyImages(propertyId);
  const [open, setOpen] = useState<boolean>(false);
  const [sequencePosition, setSequencePosition] = useState<number>();
  const [imageId, setImageId] = useState<number>();

  function handleEdit(seq: number) {
    setSequencePosition(seq);
    setOpen(true);
  }

  const handleDeleteImage = async (imageId: number) => {
    setImageId(imageId);
  };

  const seqPosition = React.useMemo(() => {
    if (images && images.length > 0) {
      return images[images.length - 1].sequence_position + 1;
    }
    return 1;
  }, [images]);

  const enablePhotoEdits = builder?.enable_photo_gallery_edits;

  return (
    <>
      {/* Add new image button */}
      {enablePhotoEdits && (
        <Grid container justify="flex-end">
          <Button startIcon={<AddIcon />} onClick={() => setOpen(true)}>New photo</Button>
        </Grid>
      )}
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell style={{ color: '#979797' }} align="center">Position</TableCell>
            <TableCell style={{ color: '#979797' }} align="center">Photo</TableCell>
            <TableCell style={{ color: '#979797' }} align="center">Created By</TableCell>
            <TableCell style={{ color: '#979797' }} align="center">Modified By</TableCell>
            {enablePhotoEdits && <TableCell style={{ color: '#979797' }} align="center">Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {images?.map((image, index) => (
            <TableRow key={index}>
              <TableCell align="center">{image.sequence_position}</TableCell>
              <TableCell align="center">
                <img src={image.image_url} alt="" className={classes.image} />
              </TableCell>
              <TableCell align="center">{image.created_by}</TableCell>
              <TableCell align="center">{image.modified_by}</TableCell>
              {enablePhotoEdits && (
                <TableCell align="center">
                  <Button onClick={() => handleDeleteImage(image.id)}><DeleteIcon /></Button>
                  <Button onClick={() => handleEdit(image.sequence_position)}><EditIcon /></Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DialogUploadImage
        edit={sequencePosition !== undefined}
        seqPosition={sequencePosition !== undefined ? sequencePosition : seqPosition}
        imagePreview={images?.find((image) => image.sequence_position === sequencePosition)?.image_url}
        propertyId={propertyId}
        open={open}
        onClose={() => {
          setOpen(false);
          setSequencePosition(undefined);
        }}
      />
      <DialogConfirmDelete
        propertyId={propertyId}
        imageId={imageId}
        open={imageId !== undefined}
        onClose={() => setImageId(undefined)}
      />
    </>
  );
};

export default React.memo(PropertyImageComponent);
