import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { WithStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import styles from './styles';

type Props = {
  sendIntegrationRequest: (payload: { message: string, name: string }) => void,
  open: boolean,
  changeOpen: () => void;
  handleChecked?: () => void;
  integrationName: string;
} & WithStyles<typeof styles>;

const IntegrationDialog: React.FC<Props> = props => {
  const {
    sendIntegrationRequest, open, changeOpen, integrationName, handleChecked,
  } = props;
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const handleChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.currentTarget.value);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (integrationName) {
      return;
    }
    setName(event.currentTarget.value);
  };

  const handleClose = () => {
    changeOpen();
    if (handleChecked) {
      handleChecked();
    }
    setMessage('');
    setName('');
  };

  const sendIntegration = () => {
    sendIntegrationRequest({ message, name: name || integrationName });
    handleClose();
  };

  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle>Intergation Request</DialogTitle>
        <DialogContent>
          {
            integrationName ? (
              <div>
                <Typography variant="caption" color="textSecondary">
                  Integration Name
              </Typography >
                <Typography>
                  {integrationName}
                </Typography>
                <Divider />

              </div>
            ) : (
                <TextField
                  value={name}
                  onChange={handleChangeName}
                  autoFocus
                  margin="dense"
                  label="Integration Name"
                  rows="4"
                  id="name"
                  color="primary"
                  fullWidth
                />
              )
          }

          <TextField
            value={message}
            onChange={handleChangeMessage}
            margin="dense"
            label="Request Text"
            rows="4"
            multiline
            id="message"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={!message}
            onClick={sendIntegration}
          >
            Send Request
          </Button>
          <Button variant="contained" onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IntegrationDialog;
